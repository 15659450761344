class OTF_Sticky_Layout_JS {
    top = 0;

    constructor() {
        this.setupValue();
        this.initSticky();
    }

    setupValue() {
        this.top      = 0;
        let $adminBar = $('#wpadminbar');
        if ($adminBar.length > 0) {
            this.top += $adminBar.height();
        }
    }

    initSticky() {
        let window_width = $(window).width();
        if (window_width < 992) {
            jQuery(".opalrealestate-MyAccount-navigation .inner,#primary [opal-ajax-map-properties] .inner,.single-style-5-wrap > div > .inner, body.opal-sidebar-sticky #secondary > .inner, body.woocommerce-checkout form.woocommerce-checkout .inner").trigger("sticky_kit:detach");
        } else {
            this._makeStickyKit();
        }

        $(window).resize(() => {
            window_width = $(window).width();
            if (window_width < 992) {
                jQuery(".opalrealestate-MyAccount-navigation .inner,#primary [opal-ajax-map-properties] .inner,.single-style-5-wrap > div > .inner, body.opal-sidebar-sticky #secondary > .inner, body.woocommerce-checkout form.woocommerce-checkout .inner").trigger("sticky_kit:detach");
            } else {
                this._makeStickyKit();
            }
        });
    }

    _makeStickyKit() {
        $('.single-style-5-wrap .inner form.cart,.single-style-5-wrap .inner .product_title').css('width', $('.single-style-5-wrap .woocommerce-cart').width())

        $("body.opal-sidebar-sticky #secondary > .inner").stick_in_parent({
            parent    : '#content > .wrap',
            offset_top: this.top
        });


        $("body.woocommerce-checkout form.woocommerce-checkout .inner").stick_in_parent({
            parent    : 'body.woocommerce-checkout form.woocommerce-checkout',
            offset_top: this.top
        });

        $('.single-style-5-wrap > div > .inner').stick_in_parent({
            parent    : '.single-style-5-wrap',
            offset_top: this.top
        });

        $('#primary [opal-ajax-map-properties] .inner').stick_in_parent({
            parent    : '#primary [opal-ajax-map-search]',
            offset_top: this.top
        });

        $('.opalrealestate-MyAccount-navigation .inner').stick_in_parent({
            parent    : '.opalrealestate-Dashboard-page',
            offset_top: this.top
        })
    }
}

$(document).ready(() => {
    new OTF_Sticky_Layout_JS();
});
